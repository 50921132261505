import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Job } from '../models/job.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  readonly rootUrl = environment.apiEndpoint;
  //readonly rootUrl="https://sandbox.loadchief.com";
  //readonly rootUrl="http://localhost:62791";
  constructor(private http: HttpClient) { }

  postJob(job: Job)
  {
    var data = JSON.stringify(job);
    var header = new HttpHeaders({'Content-Type':'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.post(this.rootUrl + '/api/jobs/PostNewJob',data,{ headers: header});  
  }


  postAcceptJob(job: Job)
  {
    var data = JSON.stringify(job);
    var header = new HttpHeaders({'Content-Type': 'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.post(this.rootUrl + '/api/JobsOtherCouriers/PostAcceptJob',data,{ headers: header});  
  }

  getMyJobs() //: Observable<Job[]>
  {
     var header = new HttpHeaders({
    'Authorization':'Bearer ' + localStorage.getItem('userToken')});
    return this.http.get<Job[]>(this.rootUrl + "/api/GetJobsForCourierAll/Jobs/{'Available','Accepted','Picked Up','Delivered','Deleted by Courier','Deleted by Admin','Expired'}",{headers:header});
    //return this.http.get<Job[]>(this.rootUrl + '/api/adminMessaging/GetAllJobs/7',);
  }

  getJob(jobId: number) //: Observable<Job>
  {
    var header = new HttpHeaders({
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.get<Job>(this.rootUrl + "/api/Jobs/GetJob/" + jobId,{headers:header});
  }

  getOtherJobs() //: Observable<Job[]>
  {
    var header = new HttpHeaders({
   'Authorization':'Bearer ' + localStorage.getItem('userToken')});
   return this.http.get<Job[]>(this.rootUrl + "/api/JobsOtherCouriers/GetJobs/{All}",{headers:header});
   //var header = new HttpHeaders({
   //'Authorization':'Bearer ' + localStorage.getItem('userToken')});
   //return this.http.get<Job[]>(this.rootUrl + '/api/GetJobsForCourierToday/Jobs/{Available}/',{ headers: header});
 }

}
