import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Job } from '../models/job.model';
import { Courier } from '../models/courier.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  //readonly rootUrl="https://sandbox.loadchief.com";
  //readonly rootUrl="http://localhost:62791";
  readonly rootUrl = environment.apiEndpoint;
  private serviceUrl =  this.rootUrl + "/api/AdminMessaging/GetTodaysJobs";
  //private serviceUrl ="http://localhost:62791/api/AdminMessaging/GetTodaysJobs"

  constructor(private http: HttpClient) { }

  getCourierAccountInfo()//: Observable<Courier>
  {
    //var params = new HttpParams().set('CourierUserName', localStorage.getItem('userName'));
    var requestHeader = new HttpHeaders({'Content-Type': 'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.get<Courier>(this.rootUrl + '/api/Couriers/GetCourier/' + localStorage.getItem('userName') ,{ headers: requestHeader});  
  }

  updateCourier(courier: Courier)
  {
    var data = JSON.stringify(courier);
    var header = new HttpHeaders({'Content-Type':'application/json',
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.put(this.rootUrl + '/api/Couriers/PutCourier',data,{ headers: header});  
  }

  getCouriers()//: Observable<Courier[]>
  {
    var requestHeader = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization':'Bearer ' + localStorage.getItem('userToken')});
      return this.http.get<Courier[]>(this.rootUrl + '/api/Couriers/GetCourierNames/',{ headers: requestHeader});  
  }

  userAuthentication(userName,password)
  {
    var data="username="+ userName +"&password=" + password + "&grant_type=password";
    var requestHeader = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    return this.http.post(this.rootUrl + '/token',data,{headers:requestHeader});
  }





}
