import {MatButtonModule, MatCheckboxModule, MatTableModule, MatSortModule,MatListModule,MatProgressSpinnerModule,MatDialogModule} from '@angular/material';
import { NgModule } from '@angular/core';
import { MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
imports: [MatButtonModule,MatCheckboxModule,MatToolbarModule,MatIconModule,MatMenuModule,
     MatTableModule, MatSortModule,MatSidenavModule,MatInputModule,MatListModule,MatCardModule,
     MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatDialogModule,MatProgressSpinnerModule,
     MatSelectModule
    ],
exports: [MatButtonModule,MatCheckboxModule,MatToolbarModule,MatIconModule,MatMenuModule,
     MatTableModule, MatSortModule,MatSidenavModule,MatInputModule,MatListModule,MatCardModule,
     MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatDialogModule,MatProgressSpinnerModule,
     MatSelectModule
    ]
})

export class MaterialModule {}