import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
   
  }

}
