import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../shared/navbar.service';


@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.scss']
})
export class SidenavbarComponent implements OnInit {
              
  isLoggedIn: boolean =true;
  title = 'Loadchief Digital Waybill';
  
  constructor(private router: Router, public navbar: NavbarService)
  {

  }
  
  ngOnInit() {}  

  logout(){
    localStorage.removeItem('userToken');
    this.navbar.visible =false;
    this.router.navigateByUrl('/login');
  }

  availableJobs(){
    this.router.navigateByUrl('/availablejobs');
  }

  gotoHome(){
    this.router.navigateByUrl('/home');
  }

  gotoOrderPanel()
  {
    this.router.navigateByUrl('/orderpanel');
  }

  gotoAccount()
  {
    this.router.navigateByUrl('/account');
  }
}
