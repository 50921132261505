import { RouteStops } from './routeStops.model';
import { Contact } from 'src/app/models/contact.model';
export class Order
{
    id: number;
    time: any;
    order_number: number;            // not required
    customer_number: string;         // not required
    order_type: string;
    cost_center: string;
    price: any;
    round_trip: boolean;
    final_price: any;  
    ready_time: any;
    deliver_by: any;
    status: string;
    origin: string;                 // not required
    status_date: string;            // not required
    status_detail: string;          // not required
    route_stops: RouteStops[];
    // constructor()
    // {
    //    this.route_stops = new RouteStops[];
    //  }
}