import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { UserService } from '../../shared/user.service';
import {FormControl, Validators} from '@angular/forms';
import { Courier } from '../../models/courier.model';

@Component({
  selector: 'app-accountdetails',
  templateUrl: './accountdetails.component.html',
  styleUrls: ['./accountdetails.component.scss']
})
export class AccountdetailsComponent implements OnInit {
  @ViewChild(MatSort) sort:MatSort;
  email = new FormControl('', [Validators.required, Validators.email]);
    dataSource;
    displayedColumns = ['id','userName','companyName','accountStatus','phone'];
    //displayedColumns = ['id','jobStatus','courierUserName','vehicleRequired'];
    courier: Courier;

  constructor(private userService: UserService) { 
    this.courier =new Courier();
    this.courier.id=12;
  }

  ngOnInit() {    
     this.getCourier();
  }

  getCourier()
  {
    this.userService.getCourierAccountInfo().subscribe(results => {
      if(!results)
      {
        alert("Error");
        return;
      }
       this.courier = <Courier>results;
       //alert(JSON.stringify(results));
    })     
  }

  updateCourier()
  {
    //alert(JSON.stringify(this.courier));
     this.userService.updateCourier(this.courier).subscribe(results => {
      if(!results)
      {
        alert("Error");
        return;
      }
      alert("Account details updated successfully");
      //this.courier =<Courier>results;
    })     
  }

}
