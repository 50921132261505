import { Component, OnInit } from '@angular/core';
import { Job } from '../models/job.model';
import {} from 'googlemaps';
import { NavbarService } from '../shared/navbar.service';
import { CxtserviceService } from '../shared/cxtservice.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  geocoder: google.maps.Geocoder;
  job: Job;
  address: string;
  latitude: string;
  longitude: string;
  statusCode: number;

  clientMapLookup = '';
  constructor(private navbar: NavbarService,private cxtserviceService: CxtserviceService) { }

  ngOnInit() {
    if(localStorage.getItem('userToken') != null)
    {
      this.navbar.visible = true;
      this.geocoder = new google.maps.Geocoder();
    }
    this.job = new Job();
    this.job.pickupAddress1 = "20 Corporate Park";
    this.job.pickupCity = "Irvine";
    this.job.pickupState = "CA";
    this.job.pickupZip = "92606";
    //let dt = new Date();
    //alert(dt.getDate());
    //let dt1 = new Date("Mon, 08 Jun 2020 13:02:34");
    //alert(dt1);
  }

  getLatLng()
  {
    this.address = this.job.pickupAddress1 + " " + this.job.pickupCity + " " + this.job.pickupState+ " " + this.job.pickupZip;
    //alert(this.address);
    if (this.geocoder == null || this.geocoder == undefined) { return; }
    this.geocoder.geocode({ 'address': this.address },
        function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              //alert(JSON.stringify(results[0].geometry.location));
                this.latitude = results[0].geometry.location.lat();
                this.longitude = results[0].geometry.location.lng();
                alert(this.latitude + ", " + this.longitude);
            }
        });   
  }

  postPayment()
  {
    alert("Post Payment Request");
    this.cxtserviceService.postPayment().subscribe((data: any)=> {
      if(!data)
      {
        alert('error');
        return;
      }
      alert(data);
      this.statusCode = data.status;
      if(this.statusCode == 503)
      {
        alert("Digital waybill server is busy, Please try after some time.");
      }
      else if (this.statusCode == 502 )
      {
        alert("Unable to communicate with the gateway servers at this time. Please contact Digital Waybill support for assistance.");
      }
      else if (this.statusCode == 404 )
      {
        alert("Gateway was unable to recognize the CID provided in the request.");
      }
      else if (this.statusCode ==400)
      {
        alert("Gateway unable to handle the request URI provided, or the HTTP request itself was invalid.");
      }
      else if(this.statusCode==200)
      {
            //alert("success");
            // this.orders = <Order[]> data.body.orders;
            // this.dataSource =new MatTableDataSource (this.orders);
            // this.dataSource.sort = this.sort;
            //this.selectedOrder = this.orders[0];
      }
    })
  }


}
