export class Job
{
    id                  : string;
    jobStatus           : string;
    createdDate			: any;
    payment				: any;
    courierTotalCost	: any;

    pickupDate			: any;    
    deliveryDate		: any;
    readyByTimeHour		: string;
    readyByTimeMinute	: string;
    pickupTimeHour		: string;
    pickupTimeMinute	: string;
    deliveryTimeHour	: string;
    deliveryTimeMinute	: string;

    pickupLatitude           : string;
    pickupLongitude          : string;
    deliveryLatitude         : string;
    deliveryLongitude        : string;

    insuranceTypeRequired    :boolean;
    dbA_CorpRequired         :boolean;
    mC_NumberRequired        :boolean;
    hazmatRequired           :boolean;
    occAccInsuranceRequired  :boolean;

    courierUserName     : string;
    pickupCompanyName	: string;
    pickupContactName	: string;
    pickupNote			: string;
    pickupPhone			: string;
    pickupAddress1		: string;
    pickupAddress2		: string;
    pickupCity			: string;
    pickupState			: string;
    pickupZip			: string;
    deliveryCompanyName	: string;
    deliveryContactName	: string;
    deliveryNote		: string;
    deliveryPhone		: string;
    deliveryAddress1	: string;
    deliveryAddress2	: string;
    deliveryCity		: string;
    deliveryState		: string;
    deliveryZip			: string;
    numberOfPackages	: string;
    descriptionOfPackages	: string;
    weightOfPackages		: string;
    referenceNumber         :string;
    vehicleRequired			: string;
    additionalRequirements	: string;
    deliveryRecipientName	: string;
    deliveryRecipientSignature	: string;
    courierUserNameAcceptedJob	: string;
    driverUserName      : string;
    timeZone: string;
    batchJob: string;
}
    // id                  : any;
    // jobStatus           : string;
    // createdDate			: any;
    // payment				: any;
    // courierTotalCost	: any;

    // pickupDate			: any;    
    // deliveryDate		: any;
    // readyByTimeHour		: any;
    // readyByTimeMinute	: any;
    // pickupTimeHour		: any;
    // pickupTimeMinute	: any;
    // deliveryTimeHour	: any;
    // deliveryTimeMinute	: any;

    // pickupLatitude           : any;
    // pickupLongitude          : any;
    // deliveryLatitude         : any;
    // deliveryLongitude        : any;

    // insuranceTypeRequired    :boolean;
    // dbA_CorpRequired         :boolean;
    // mC_NumberRequired        :boolean;
    // hazmatRequired           :boolean;
    // occAccInsuranceRequired  :boolean;

    // courierUserName     : string;
    // pickupCompanyName	: string;
    // pickupContactName	: string;
    // pickupNote			: string;
    // pickupPhone			: string;
    // pickupAddress1		: string;
    // pickupAddress2		: string;
    // pickupCity			: string;
    // pickupState			: string;
    // pickupZip			: string;
    // deliveryCompanyName	: string;
    // deliveryContactName	: string;
    // deliveryNote		: string;
    // deliveryPhone		: string;
    // deliveryAddress1	: string;
    // deliveryAddress2	: string;
    // deliveryCity		: string;
    // deliveryState		: string;
    // deliveryZip			: string;
    // numberOfPackages	: any;
    // descriptionOfPackages	: string;
    // weightOfPackages		: any;
    // referenceNumber         :string;
    // vehicleRequired			: string;
    // additionalRequirements	: string;
    // deliveryRecipientName	: string;
    // deliveryRecipientSignature	: string;
    // courierUserNameAcceptedJob	: string;
    // driverUserName      : string;
    // timeZone: string;
    // batchJob: string;