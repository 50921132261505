
import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './account/login/login.component';
import { Component } from '@angular/core';
import { AccountComponent } from './account/account.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UserloginComponent } from './account/userlogin/userlogin.component';
import { JobsAvailableListingComponent } from './jobs-available-listing/jobs-available-listing.component';
import { AccountdetailsComponent } from './account/accountdetails/accountdetails.component';
import { OrderpanelComponent } from './orderpanel/orderpanel.component';

export const appRoutes : Routes =[
    {path: 'home', component:HomeComponent },
    {path: 'orderpanel', component:OrderpanelComponent },
    {path: 'availablejobs', component:JobsAvailableListingComponent },
    {path: 'login', component:AccountComponent,
    children :[{path:'',component:UserloginComponent}] 
    },
    {path: 'account', component:AccountComponent,
    children :[{path:'',component:AccountdetailsComponent}] 
    },
    {path: '', redirectTo:'/login',pathMatch:'full'},
    {path:"**", component:PagenotfoundComponent}
];

