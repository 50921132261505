import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemotepanelService {
  //readonly rootUrl="https://sandbox.loadchief.com";
  //readonly rootUrl="http://localhost:53176";
  readonly rootUrl = environment.apiEndpoint;
  private serviceUrl =  this.rootUrl  + "/api/AdminMessaging/GetTodaysJobs";
  //private serviceUrl ="http://localhost:62791/api/AdminMessaging/GetTodaysJobs"


  constructor(private http: HttpClient) { }

  getDigitalWaybillJobs(cid,apikey)//: Observable<Job[]>{
    {
      //return this.http.get('http://localhost:4200/assets/orders.json'); //
      return this.http.get('https://api.dwaybill.com/' + cid + '/orders.json?v=1&key=' + apikey);
    }

    postDigitalWaybillJob(order,cid)
    {
      var data = JSON.stringify(order);
      var header = new HttpHeaders({'Content-Type':'application/json'});
      return this.http.post('https://api.dwaybill.com/' + cid + '/orders.json',data,{ headers: header});
    }
}
