import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import {FormControl, Validators} from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { NavbarService } from '../../shared/navbar.service';



@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.scss']
})
export class UserloginComponent implements OnInit {
  hide = true;
  user:User;
  userName="app";
  password="pwd";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  isLoginError: boolean =false;
  constructor(private userService: UserService, private router: Router, private http:HttpClient, private navbar: NavbarService) { }

  ngOnInit() {
    //this.OnSubmit('test','test');
  }

  updateValue(e)
  {
    this.userName = e.target.value;
    console.log(e.target.value);
  }
  updatePwd(p)
  {
    this.password = p.target.value;
    console.log(p.target.value);
  }
 
  // OnSubmit()
  // {
  //   let dt = new Date();
  //   let dt1 = new Date("Wed, 11 Jun 2020 11:33:08");
  //   if(dt1.getTime()< dt.getTime())
  //     alert("Please change Date");
  //     else
  //     alert("job posted");
  // }
    OnSubmit()
    {
      this.userService.userAuthentication(this.userName,this.password).subscribe((data: any)=> {
        //alert(data.access_token);
        localStorage.setItem('userName',this.userName);
        localStorage.setItem('userToken',data.access_token);
        this.navbar.visible = true;
        this.router.navigate(['/home']);        
        this.isLoginError= false;
      },
      (err: HttpErrorResponse)=> {
        alert("Invalid Username/Password");
        this.isLoginError = true;
      });
    }

}
