import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// import { CustomMaterialModule } from './core/material.module';
import { MaterialModule } from './material';
import { appRoutes } from './routes';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AccountComponent } from './account/account.component';
import { LoginComponent } from './account/login/login.component';
import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { UserloginComponent } from './account/userlogin/userlogin.component';
import { JobsAvailableListingComponent } from './jobs-available-listing/jobs-available-listing.component';
import { AccountdetailsComponent } from './account/accountdetails/accountdetails.component';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { OrderpanelComponent } from './orderpanel/orderpanel.component';
import { CxtpaymentComponent } from './cxtpayment/cxtpayment/cxtpayment.component';


@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    LoginComponent,
    HomeComponent,
    PagenotfoundComponent,
    SidenavComponent,
    UserloginComponent,
    JobsAvailableListingComponent,
    AccountdetailsComponent,
    SidenavbarComponent,
    OrderpanelComponent,
    CxtpaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
