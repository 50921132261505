import { Contact } from "src/app/models/contact.model";
import { Package } from "src/app/models/package.model";

export class RouteStops
{
    company: string;                   //company name
    address: string;                   //address
    suite: string;                     // address2
    city: string;
    state: string;
    postal_code: string;
    country: string;
    contact: Contact;                    //contact object
    package : string;
    number_of_pieces: number;
    weight: number;
    packages: Package;
    vehicle: string;
    driver_number: string;
    signature_contact: string;
    reference: string;
    signature: string;
    pickup_date: any;
    delivery_date: any;
    notes: string;
    special_instructions: string;
    service_type: string;
    // constructor()
    // {
    //     //this.contact = new Contact();
    // }
}

