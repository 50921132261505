import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatSortModule } from '@angular/material';
import { UserService } from '../shared/user.service';
import {FormControl, Validators} from '@angular/forms';
import { RemotepanelService } from '../shared/remotepanel.service';
import { Order } from '../models/order.model';
import { JobService } from '../shared/job.service';
import { Job } from '../models/job.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Courier } from '../models/courier.model';
import { Router } from '@angular/router';
import {} from 'googlemaps';
import { NavbarService } from '../shared/navbar.service';


@Component({
  selector: 'app-orderpanel',
  templateUrl: './orderpanel.component.html',
  styleUrls: ['./orderpanel.component.scss']
})
export class OrderpanelComponent implements OnInit {
  @ViewChild(MatSort) sort:MatSort;
  email = new FormControl('', [Validators.required, Validators.email]);
  dataSource;
  displayedColumns = ['id','time','customer_number','order_number','origin','company','address','name','phone','details'];
  orders:Order[];
  selectedOrder: Order;
  order_number: number;
  showTable: boolean=true;
  job: Job;
  cid: string;
  apikey: string;
  courier: Courier;
  statusCode: number;
  geocoder: google.maps.Geocoder;
  pickupLatitude: number;
  pickupLongitude: number;
  deliveryLatitude: number;
  deliveryLongitude: number;

    constructor(private userService: UserService,private remotepanelService: RemotepanelService,
    private jobService: JobService, private router: Router,private navbar: NavbarService) {
      this.selectedOrder = new Order();
    }

  ngOnInit() {
    if(localStorage.getItem('userToken') != null)
    {
      this.navbar.visible = true;
      this.geocoder = new google.maps.Geocoder();
    }
    else
    {
      this.router.navigate(['/login']);
    }
    this.getCourier();
  }



  getOrders()
  {
    this.remotepanelService.getDigitalWaybillJobs(this.cid,this.apikey).subscribe((data: any)=> {
      if(!data)
      {
        alert('error');
        return;
      }
      this.statusCode = data.status;
      if(this.statusCode == 503)
      {
        alert("Digital waybill server is busy, Please try after some time.");
      }
      else if (this.statusCode == 502 )
      {
        alert("Unable to communicate with the gateway servers at this time. Please contact Digital Waybill support for assistance.");
      }
      else if (this.statusCode == 404 )
      {
        alert("Gateway was unable to recognize the CID provided in the request.");
      }
      else if (this.statusCode ==400)
      {
        alert("Gateway unable to handle the request URI provided, or the HTTP request itself was invalid.");
      }
      else if(this.statusCode==200)
      {
            //alert("success");
            this.orders = <Order[]> data.body.orders;
            this.dataSource =new MatTableDataSource (this.orders);
            this.dataSource.sort = this.sort;
            //this.selectedOrder = this.orders[0];
      }
    })
  }

  getCourier()
  {
    this.userService.getCourierAccountInfo().subscribe(results => {
      if(!results)
      {
        alert("Error");
        return;
      }
       this.courier = <Courier>results;
       if(this.courier.dwbApiKey != "" && this.courier.dwbApiKey != null)
       {
         this.cid = this.courier.dwbCourierID;
         this.apikey = this.courier.dwbApiKey ;
         //alert("Ready fetch orders from Digital Waybill" +this.cid + ", " + this.apikey);
         //alert('https://api.dwaybill.com/' + this.cid + '/orders.json?v=1&key=' + this.apikey);
         this.getOrders();
       }
       else
       {
         alert("Please update your Digital Waybill API Key")
         this.router.navigateByUrl('/account');
       }
       //alert(JSON.stringify(results));
    })     
  }

  async selectedRow(order)
  {
    this.selectedOrder = order;

   this.getLatLng(this.selectedOrder.route_stops[0].address  + " " +
   this.selectedOrder.route_stops[0].city + " " + this.selectedOrder.route_stops[0].state + " " + this.selectedOrder.route_stops[0].postal_code , "pickup");
  
    this.getLatLng(this.selectedOrder.route_stops[1].address + " " +
    this.selectedOrder.route_stops[1].city + " " + this.selectedOrder.route_stops[1].state + " " + this.selectedOrder.route_stops[1].postal_code ,"delivery");    
   
    this.showTable= false;
  }

  getLatLng(address: string,location: string) 
  {
  
    if (this.geocoder == null || this.geocoder == undefined) { return; }
    this.geocoder.geocode({ 'address': address }, (results, status) =>{
      if (status == google.maps.GeocoderStatus.OK) {
        if(location == "pickup")
        {
          this.pickupLatitude = results[0].geometry.location.lat();
          this.pickupLongitude = results[0].geometry.location.lng();
        }
        else
        {
          this.deliveryLatitude = results[0].geometry.location.lat();
          this.deliveryLongitude = results[0].geometry.location.lng();
        }
      }
    });   
  }

  postJob()
  {
    var pickupDate = new Date(this.selectedOrder.ready_time);
    var deliveryDate = new Date(this.selectedOrder.deliver_by);

    //check pickup and delivery date is valid or not
    let DateTimeNow = new Date();
    if(pickupDate.getTime()>= DateTimeNow.getTime() && deliveryDate.getTime() >= DateTimeNow.getTime())
    {
      this.job = new Job();
      // Pickup and Delivery Date
  
      //this.job.pickupDate= (pickupDate.getMonth() + 1)  + "/" +  pickupDate.getDate() + "/" + pickupDate.getFullYear();
      //this.job.deliveryDate = (deliveryDate.getMonth() + 1)  + "/" +  deliveryDate.getDate() + "/" + deliveryDate.getFullYear();
      
      //this.job.pickupDate= (pickupDate.getMonth() + 1)  + "/" +  pickupDate.getDate() + "/" + pickupDate.getFullYear();
      ///this.job.deliveryDate = (deliveryDate.getMonth() + 1)  + "/" +  deliveryDate.getDate() + "/" + deliveryDate.getFullYear();
   
      // Pickup and Delivery TimeHour
  
      var readyTime = new Date(this.selectedOrder.ready_time);
      var deliveryTime = new Date(this.selectedOrder.deliver_by);
      this.job.pickupDate= readyTime;
      this.job.deliveryDate = deliveryTime;
      this.job.pickupTimeHour = readyTime.getHours().toString();
      this.job.readyByTimeHour = readyTime.getHours().toString();
      this.job.pickupTimeMinute = readyTime.getMinutes().toString();
      this.job.readyByTimeMinute = readyTime.getMinutes().toString();
      this.job.deliveryTimeHour = deliveryTime.getHours().toString();
      this.job.deliveryTimeMinute = deliveryTime.getMinutes().toString();
      
      //this.job.pickupLatitude = "1";
      //this.job.pickupLongitude = "1";
      //this.job.deliveryLatitude = "1";
      //this.job.deliveryLongitude = "1";
      this.job.insuranceTypeRequired=null;
      this.job.dbA_CorpRequired =null;
      this.job.mC_NumberRequired =null;
      this.job.hazmatRequired =null;
      this.job.occAccInsuranceRequired=null;
  
  
      this.job.pickupCompanyName = this.selectedOrder.route_stops[0].company;
      this.job.pickupContactName =this.selectedOrder.route_stops[0].contact.name;
      this.job.pickupAddress1 = this.selectedOrder.route_stops[0].address;
      this.job.pickupAddress2 = this.selectedOrder.route_stops[0].suite;
      this.job.pickupCity = this.selectedOrder.route_stops[0].city;
      this.job.pickupState = this.selectedOrder.route_stops[0].state;
      this.job.pickupZip = this.selectedOrder.route_stops[0].postal_code;
      this.job.pickupPhone = this.selectedOrder.route_stops[0].contact.phone;
      this.job.pickupLatitude = this.pickupLatitude.toString();
      this.job.pickupLongitude = this.pickupLongitude.toString();
      //this.getLatLng(this.job.pickupAddress1 + " " + this.job.pickupAddress2 + " " + this.job.pickupCity + " " + this.job.pickupState  + " " + this.job.pickupZip,"pickup");
      //delivery information
      this.job.deliveryCompanyName = this.selectedOrder.route_stops[1].company;
      this.job.deliveryContactName =this.selectedOrder.route_stops[1].contact.name;
      this.job.deliveryAddress1 = this.selectedOrder.route_stops[1].address;
      this.job.deliveryAddress2 = this.selectedOrder.route_stops[1].suite;
      this.job.deliveryCity = this.selectedOrder.route_stops[1].city;
      this.job.deliveryState = this.selectedOrder.route_stops[1].state;
      this.job.deliveryZip = this.selectedOrder.route_stops[1].postal_code;
      this.job.deliveryPhone = this.selectedOrder.route_stops[1].contact.phone;
      this.job.deliveryLatitude = this.deliveryLatitude.toString();
      this.job.deliveryLongitude = this.deliveryLongitude.toString();
  
      //this.getLatLng(this.job.deliveryAddress1 + " " + this.job.deliveryAddress2 + " " + this.job.deliveryCity + " " + this.job.deliveryState  + " " + this.job.deliveryPhone,"Delivery");
  
      //package and vehicle details
      this.job.payment = this.selectedOrder.price;
      this.job.vehicleRequired = this.selectedOrder.route_stops[1].package;
      this.job.descriptionOfPackages = this.selectedOrder.route_stops[1].package;
      this.job.weightOfPackages = this.selectedOrder.route_stops[1].weight.toString();
      this.job.numberOfPackages= this.selectedOrder.route_stops[1].number_of_pieces.toString();
      this.job.referenceNumber = this.selectedOrder.route_stops[1].reference.toString();
      this.job.additionalRequirements = this.selectedOrder.route_stops[1].special_instructions.toString();
  
      //Time zone and BatchJob=Individual

      var newDate = new Date();
      var dateStr = newDate.toString();
      var res = dateStr.split(" ");
      this.job.timeZone = res[5];
      this.job.batchJob = "0";
  
      //alert(JSON.stringify(this.job));
      this.jobService.postJob(this.job).subscribe((data: any)=> {
        alert("Job posted successfully");
      },
      (err: HttpErrorResponse)=> {
        alert("Error, could not post job" + err.error  + "  " + err.message + "  " + err.statusText +  "  " + err.status + "  " + err.headers );
      });
    }
    else
    {
      alert("Pickup Date and Delivery Date should be equal to or greater than present date");
    }
  }

  showTableInView()
  {
    this.showTable= true;
  }
}
