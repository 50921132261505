import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { UserService } from '../shared/user.service';
import { JobService } from '../shared/job.service';
import {FormControl, Validators} from '@angular/forms';
import { Order } from '../models/order.model';
import { Job } from '../models/job.model';
import { RouteStops } from '../models/routeStops.model';
import { Contact } from 'src/app/models/contact.model';
import { RemotepanelService } from '../shared/remotepanel.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Courier } from '../models/courier.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobs-available-listing',
  templateUrl: './jobs-available-listing.component.html',
  styleUrls: ['./jobs-available-listing.component.scss']
})
export class JobsAvailableListingComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  email = new FormControl('', [Validators.required, Validators.email]);
  dataSource;
  displayedColumns = ['id','jobStatus','pickupCity','deliveryCity','vehicleRequired','myJobs'];
  selected = 'myJobs';
  isShowTable=true;
  job: Job;
  jobs: Job[];
  order: Order;
  pickup: RouteStops;
  delivery: RouteStops;
  cid: string;
  courier: Courier;
  statusCode: number;


  constructor(private userService: UserService, private jobService: JobService,
     private remotepanelService: RemotepanelService, private router: Router) {
      this.job = new Job();
    this.order  = new Order();
  }

  ngOnInit() {
    this.getMyJobs();
  }
  
  getCourier()
  {
    this.userService.getCourierAccountInfo().subscribe(results => {
      if(!results)
      {
        alert("Error");
        return;
      }
       this.courier = <Courier>results;
       if(this.courier.dwbCourierID != "" && this.courier.dwbCourierID != null)
       {
          this.cid = this.courier.dwbCourierID;
          alert(this.cid);
          this.remotepanelService.postDigitalWaybillJob(this.order,this.cid).subscribe((data: any)=> {
            alert("Job posted successfully " + JSON.stringify(data));
          },
          (err: HttpErrorResponse)=> {
            alert("Error, could not post job" + err.error  + "  " + err.message + "  " + err.statusText +  "  " + err.status + "  " + err.headers );
          });
       }
       else
       {
         alert("Please update your Digital Waybill Customer ID")
         this.router.navigateByUrl('/account');
       }
       //alert(JSON.stringify(results));
    })     
  }

  getMyJobs()
  {
    this.jobService.getMyJobs().subscribe(data => {
      if(!data)
      {
        alert('error');
        return;
      }
      this.jobs = <Job[]> data;
      this.dataSource =new MatTableDataSource (this.jobs);
      this.dataSource.sort = this.sort;
    })
  }

  showDetails(job: Job)
  {
    this.isShowTable =false;
    this.jobService.getJob(parseInt(job.id)).subscribe(results => {
      if(!results)
      {
        alert('error');
        return;
      }
      this.job = results;
      var date=this.job.pickupDate.split("T");
      var newPickupDate = new Date(date[0] + " " + this.job.pickupTimeHour + ":" + this.job.pickupTimeMinute + ":00" );
      var rfcDate=newPickupDate.toString().split(" ");
      this.job.pickupDate = rfcDate[0] + ", " + rfcDate[1] + " " + rfcDate[2] + " " + rfcDate[3] + " "  + rfcDate[4];
      date=this.job.deliveryDate.split("T");
      var newDeliveryDate = new Date(date[0] + " " + this.job.deliveryTimeHour + ":" + this.job.deliveryTimeMinute + ":00" );
      rfcDate=newDeliveryDate.toString().split(" ");
      this.job.deliveryDate = rfcDate[0] + ", " + rfcDate[1] + " " + rfcDate[2] + " " + rfcDate[3] + " "  + rfcDate[4];
    })
  }

  showTableInView()
  {
    this.isShowTable =true;
  }

  acceptJob(job: Job)
  {
    alert("Job accepted" + job.id);
    this.jobService.postAcceptJob(job).subscribe(results => {
      if(!results)
      {
        alert('error');
        return;
      }
      alert("Job Accepted Successfully, You can view this job in My jobs section.");
    })
    this.selected="myJobs";
    this.refreshList();
  }

  postJob()
  {
    
    //this.order.order_number = parseInt(this.job.id);
    //this.order.price = this.job.payment;
    this.order.customer_number = "2000105850";
    this.order.cost_center = null;
    this.order.order_type = "Pickup";
    this.order.ready_time = this.job.pickupDate;
    this.order.round_trip = true;
    this.order.route_stops = [new RouteStops(),new RouteStops()];      
    this.order.route_stops[0].company = this.job.deliveryCompanyName;
    this.order.route_stops[0].address = this.job.deliveryAddress1;
    this.order.route_stops[0].suite = this .job.deliveryAddress2;
    this.order.route_stops[0].city = this.job.deliveryCity;
    this.order.route_stops[0].state = this.job.deliveryState;
    this.order.route_stops[0].postal_code = this.job.deliveryZip;
    this.order.route_stops[0].country = "USA";
    this.order.route_stops[0].contact = new Contact();
    this.order.route_stops[0].contact.name = this.job.deliveryContactName;
    this.order.route_stops[0].contact.phone = this.job.deliveryPhone;
    // //delivery information
    this.order.route_stops[1].company = this.job.pickupCompanyName;
    this.order.route_stops[1].address = this.job.pickupAddress1;
    this.order.route_stops[1].suite = this .job.pickupAddress2;
    this.order.route_stops[1].city = this.job.pickupCity;
    this.order.route_stops[1].state = this.job.pickupState;
    this.order.route_stops[1].postal_code = this.job.pickupZip;
    this.order.route_stops[1].country = "USA";
    this.order.route_stops[1].contact = new Contact();
    this.order.route_stops[1].contact.name = this.job.pickupContactName;
    this.order.route_stops[1].contact.phone = this.job.pickupPhone;
    
    // notes, package and vehicle details
    this.order.route_stops[1].notes = null;
    this.order.route_stops[1].reference = this.job.referenceNumber;
    this.order.route_stops[1].special_instructions = "From Loadchief platform";
    this.order.route_stops[1].service_type = null;
    this.order.route_stops[1].package = this.job.descriptionOfPackages;
    this.order.route_stops[1].number_of_pieces = parseInt(this.job.numberOfPackages);
    this.order.route_stops[1].weight = parseInt(this.job.weightOfPackages);
    this.order.route_stops[1].vehicle = this.job.vehicleRequired;
    this.order.route_stops[1].pickup_date = this.job.pickupDate;
    this.order.route_stops[1].delivery_date = this.job.deliveryDate;  
    
    // this.job.descriptionOfPackages = this.selectedOrder.route_stops[1].package;
    // this.job.weightOfPackages = this.selectedOrder.route_stops[1].weight.toString();
    // this.job.numberOfPackages= this.selectedOrder.route_stops[1].number_of_pieces.toString();
    // this.job.referenceNumber = this.selectedOrder.route_stops[1].reference.toString();

    //alert(JSON.stringify(this.order)); // + JSON.stringify(this.pickup) + JSON.stringify(this.delivery));
    this.getCourier();
  }

  getOtherJobs()
  {
    this.jobService.getOtherJobs().subscribe(results => {
      if(!results)
      {
        alert('error');
        return;
      }
       this.dataSource =new MatTableDataSource (results);
       this.dataSource.sort = this.sort;
    })
  }

  refreshList()
  {
    if(this.selected=="myJobs")
    {
      this.getMyJobs();
      this.displayedColumns.pop();
      this.displayedColumns.push("myJobs");
    }
    else if(this.selected=="otherJobs")
    {
      this.getOtherJobs();
      this.displayedColumns.pop();
      this.displayedColumns.push("otherJobs");
    }
  }

}
