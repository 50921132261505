import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { UserService } from '../../shared/user.service';
import {FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
@ViewChild(MatSort) sort:MatSort;
email = new FormControl('', [Validators.required, Validators.email]);
  dataSource;
  displayedColumns = ['id','jobStatus','courierUserName','vehicleRequired'];

  constructor(private userService: UserService) { }

  ngOnInit() {
    
      // this.userService.getJobs().subscribe(results => {
      //   if(!results)
      //   {
      //     return;
      //   }
      //    this.dataSource =new MatTableDataSource (results);
      //    this.dataSource.sort = this.sort;
      //   //alert("success");
      // })
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

}
