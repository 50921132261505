export class Courier
{
    id: number;
    userName: string;
    accountStatus: string;
    companyName: string;
    contactName: string;
    address1	: string;
    address2	: string;
    city		: string;
    state		: string;
    zip			: string;
    phone       : string;
    emailAddressForJobStatusUpdates: string;
    stripeCustomerID: string;
    stripeConnectID: string;
    courierEmail: string;
    courierID: string;
    apiKey: string;
    dwbCourierID: string;
    dwbApiKey: string;    
}