import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CxtserviceService {
  //readonly rootUrl="https://sandbox.loadchief.com";
  //readonly rootUrl="http://localhost:53176";
  readonly rootUrl = environment.apiEndpoint;
  private serviceUrl =  this.rootUrl  + "/api/AdminMessaging/GetTodaysJobs";
  //private serviceUrl ="http://localhost:62791/api/AdminMessaging/GetTodaysJobs"
  private clientUsername="XDISPATCH-LoadChief";
  private clientPassword="u8i9o0klp";

  constructor(private http: HttpClient) { }


  postPayment()//: Observable<Job[]>{
    {
      //return this.http.get('http://localhost:4200/assets/orders.json'); //
      //var username= this.clientUsername;
      //var password = this.clientPassword;
      var encodedString = btoa(this.clientUsername + ":" + this.clientPassword);
      // var header = new HttpHeaders({'Content-Type':'application/json'});
      // header.append("Authorization","Basic " + encodedString);
      alert(encodedString);
      console.log(encodedString);
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Basic " + encodedString
         });
      var payLoad='{"jobId":"1700","payment":"166.23","paymentType":"FinalPay"}';         
      return this.http.post(
        'https://lcfapp.azurewebsites.net/CXTAPI',
        //'https://9998.cxtsoftware.net/CxtWebService/CxtWebServiceRest.svc/rest/process/LoadChiefWeb',
        payLoad,
        {headers : headers});
    }

    postCXTJob(order,cid)
    {
      var data = JSON.stringify(order);
      var header = new HttpHeaders({'Content-Type':'application/json'});
      return this.http.post('https://api.dwaybill.com/' + cid + '/orders.json',data,{ headers: header});
    }

}
